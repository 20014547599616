<template>
  <div 
    v-if="showTitle && goodsName"
    class="product-card__goods-title-container"
  >
    <a
      ref="nameLink"
      :href="forbidJumpDetail ? 'javascript:void(0);' : goodsDetailUrl"

      class="goods-title-link"
      :class="{ 
        'goods-title-link--jump': !forbidJumpDetail,
        'goods-title-link--twoline': showGoodsNameTwoLine
      }"

      :aria-label="goodsName"
      tabindex="0"
      @click="handleClickGoodsName"
      @keydown.enter.stop="handleClickGoodsName"
      @contextmenu="showMenu"
    >
      <slot name="beforeTitle">
        <product-card-title-tag 
          v-if="leftTagDisplayName === 'ProductCardTitleTag'"
          :tag-data="goodsNameTag"
        />
        <discount-label 
          v-else-if="leftTagDisplayName === 'DiscountLabel'"
          type="discount"
          :discount-info="discountInfo"
        />
      </slot>

      {{ goodsName }}
    </a>
  </div>
</template>

<script name="ProductItemTitleContainer" setup>
import { ref, toRefs, inject, computed, watch  } from 'vue'
import { isDeSuggestedSalePrice } from '../../utils/priceDisplayTheResults.js'

import ProductCardTitleTag from './ProductCardTitleTag.vue'
import DiscountLabel from '../common/DiscountLabel.vue'

const emit = defineEmits(['clickGoodsName'])

const labelsFromKey = inject('labelsFromKey')
const config = inject('config', () => { return {} })
const setAttrForAnalysis = inject('setAttrForAnalysis')
const item = inject('item', () => {})

const showTitle = config?.showTitle
// 是否展示两行
const showGoodsNameTwoLine = config.showGoodsNameTwoLine

// 是否展示商品名称前标签
const hasOwnPropertyShowGoodsNameLabel = config.hasOwnProperty('showGoodsNameLabel')
const showGoodsNameLabel = !hasOwnPropertyShowGoodsNameLabel || (hasOwnPropertyShowGoodsNameLabel && config.showGoodsNameLabel)

// 是否在标题前展示折扣标签
const showDiscountLabelBeforeGoodsName = config.showDiscountLabelBeforeGoodsName
const showDiscountLabelAfterPrice = config.showDiscountLabelAfterPrice
// 是否把折扣标签优先级提到最高
const showDiscountLabelBeforeGoodsNameHighPriority = config.showDiscountLabelBeforeGoodsNameHighPriority
// 是否隐藏折扣标签
const { pretreatInfo: { suggestedSalePriceConfigInfo: { config: { hideDiscount } = {} } = {} } = {} } = item.value ?? {}

const props = defineProps({
  showSelect: {
    type: Boolean,
    default: false
  },
  discountInfo: {
    type: Object,
    default() {
      return {}
    }
  },
  goodsInfo: {
    type: Object,
    default: () => ({}),
  },
})

const { discountInfo, goodsInfo } = toRefs(props)

// 商品名称
const goodsName = computed(() => {
  return goodsInfo.value?.goods_name ?? ''
})

// 商品详情页链接
const goodsDetailUrl = computed(() => {
  return goodsInfo.value?.detail_url ?? 'javascript:void(0)'
})

const forbidJumpDetail = computed(() => {
  return props.showSelect || config.disableMainimgJump
})

const nameLink = ref(null)

// 商品标签
// backgroundColor, fontColor, goodsRange, labelLang, labelLangEn
const goodsNameTag = ref({})

// 计算出显示的组件
const leftTagDisplayName = ref('')

watch(
  () => goodsInfo.value.goods_sn,
  () => {

    // goodsNameLabel: {
    //         backgroundColor: "#3cbd45",
    //         fontColor: "#ffffff",
    //         labelLang: "NEW",
    //         labelLangEn: "NEW",
    //         labelId: "600012242",
    //       },

    // 德国合规价，写死折扣标签在标题左边
    const isDeSuggestedSalePriceType = isDeSuggestedSalePrice(discountInfo.value?.suggestedSalePriceType)
    const deShowDiscountLabel = isDeSuggestedSalePriceType && ( showDiscountLabelBeforeGoodsName || showDiscountLabelAfterPrice )

    const goodsNameTagNewValue = goodsInfo.value?.[labelsFromKey]?.goodsNameLabel ?? {}

    // 默认商品名称前(new,hot等)标签 优先级高于 折扣标签
    let compArr = [
      {
        name: 'ProductCardTitleTag',
        hasData: showGoodsNameLabel ? goodsNameTagNewValue?.labelLang : false
      },
      {
        name: 'DiscountLabel',
        hasData: (deShowDiscountLabel || showDiscountLabelBeforeGoodsName || showDiscountLabelBeforeGoodsNameHighPriority) && !hideDiscount && discountInfo.value?.show
      }
    ]
    
    // 倒转
    if (showDiscountLabelBeforeGoodsNameHighPriority) {
      compArr = compArr.reverse()
    }

    leftTagDisplayName.value = compArr.find(({ hasData }) => {
      return !!hasData
    })?.name ?? ''

    if (leftTagDisplayName.value === 'ProductCardTitleTag') {
      goodsNameTag.value = goodsNameTagNewValue

      const frontTitle = goodsInfo.value?.pretreatInfo?.frontTitle
      const imgUpperLeft = goodsInfo.value?.pretreatInfo?.imgUpperLeft

      const labelLangEn = goodsNameTagNewValue.labelLangEn.toLowerCase()
      setAttrForAnalysis((el) => {
        const badges = []
        const upLeftBadges = frontTitle?.code || imgUpperLeft?.code

        if (upLeftBadges) badges.push(upLeftBadges)
        el.setAttribute('data-badges', badges)

        el.setAttribute('data-title-label', `show_title_label_${goodsNameTagNewValue.labelId}_${labelLangEn}`)
      })
    }
  },
  {
    immediate: true
  }
)

const handleClickGoodsName = (e) => {
  emit('clickGoodsName', { event: e, target: nameLink.value })
}

const showMenu = (e) => {
  if (forbidJumpDetail.value) {
    e.preventDefault()
  }
}
</script>

<style lang="less" scoped>
.product-card__goods-title-container {

  .goods-title-link {
    .line-camp(1);

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    word-break: break-all;
    vertical-align: middle;
    line-height: 16px;

    font-size: 14px;
    color: var(--sui-color-gray-dark-1, #000);

    /* stylelint-disable-next-line */
    :deep(.product-card__discount-label) {
      display: inline-block;
      height: 16px;
      line-height: 16px;
    }

  }

  /* stylelint-disable-next-line selector-class-pattern */
  .goods-title-link--jump {
    /* stylelint-disable-next-line selector-max-specificity */
    .goods-title-link:hover {
      cursor: pointer;
      color: #222;
      text-decoration: underline;
    }
  }
  /* stylelint-disable-next-line selector-class-pattern */
  .goods-title-link--twoline {
    .line-camp(2);
  }
}
</style>
