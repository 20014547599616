
import ProductLabelCarousel from './ProductLabelCarousel.vue'
import { getEfficientLabels } from './logic'

const sortLabels = [
  (item) => {
    const text = item?.pretreatInfo?.stockLeft
    if (!text) return ''
    return {
      icon: 'warn',
      key: '-1',
      text
    }
  },
  '3526',
  '3527',
  '1661',
  '207',
  '3460',
  '3457',
  '3726',
  '3454'
]

const getLabelCarousel = ({ item }) => {
  const { efficientLabels = [] } = getEfficientLabels(item, sortLabels)
  const isSoldout = item.is_on_sale == 0 || item.stock == 0 || item.sale_percent == 1
  return efficientLabels.length > 0 && !isSoldout
}

const setLabelCarouselAna = ({ el, item }) => {
  const { saLabels = [] } = getEfficientLabels(item, sortLabels)
  if (saLabels.length) {
    el.setAttribute('data-label-carousel', saLabels.join('|'))
  }
}

export {
  ProductLabelCarousel,
  getLabelCarousel,
  setLabelCarouselAna
}
