import { computed, onMounted, reactive } from 'vue'
import { setLabelCarouselAna } from '../components/ProductCardSlots/ProductLabelCarousel/index.js'

export function useAttrForAnalysis({
  imgContainerRef = {},
  nameContainerRef = {},
  index,
  item,
  config,
  goodsInfo = {},
  pretreatInfo = {},
  locateLabels = {},
  locateLabelsCtrl = {},
  spuImg = {},
  _showSpuImg = {},
  saleout,
  estimatedPriceInfo,
  beltPriceCtrl = {},
  labelsFromKey
}) {
  let isClient = false
  let analysisWaitingQueue = reactive([])

  /** 商品名字左侧 hot标 */
  const showHotTag = computed(() => {
    if ((pretreatInfo.value?.tspLabels?.hot_color || goodsInfo.value?.tspLabels?.hot_color) == 1) {
      return 1
    }

    return 0
  })

  /** 图片左上角角标 new 内容其实是配置的，后期可以随意新增 */
  const imgUpperLeft = computed(() => {
    return (pretreatInfo.value && pretreatInfo.value.imgUpperLeft) || null
  }) 

  onMounted(() => {
    isClient = true
  })

  const clearAnalysisWaitingQueue = () => {
    analysisWaitingQueue.forEach((fun) => {
      fun()
    })

    analysisWaitingQueue = []
  }

  const setAttrForAnalysis = fn => {
    const setElAttr = (el, isMainCtn = true) => {
      typeof fn === 'function' && fn(el, isMainCtn)
    }

    const setRefElAttr = () => {
      imgContainerRef.value && setElAttr(imgContainerRef.value?.$el)

      if (nameContainerRef.value?.$refs?.nameLink) {
        setElAttr(nameContainerRef.value?.$refs?.nameLink, false)
      }
    }

    if (isClient) {
      setRefElAttr()

      // 清空埋点队列
      analysisWaitingQueue.length && clearAnalysisWaitingQueue()
    } else {
      analysisWaitingQueue.push(() => {
        setRefElAttr()
      })
    }
  }

  const setElAttr = () => {
    setAttrForAnalysis((el, isImageContainer) => {
      if (!config.useOwnClickExposeAnalysis) {
        el.setAttribute('da-event-click', config.daEventClickId || '2-3-1')
        isImageContainer && config.daEventExposeId && el.setAttribute('da-event-expose', config.daEventExposeId)
      }
      const mixPromotionInfo = pretreatInfo.value?.mixPromotionInfo || []

      const extMarks = goodsInfo.value?.ext?.marks ? `marks_${goodsInfo.value.ext.marks}` : ''

      el.setAttribute('index', index)
      el.setAttribute('data-spu', goodsInfo.value?.productRelationID || '')
      el.setAttribute('data-sku', goodsInfo.value?.goods_sn || '')
      el.setAttribute('data-id', goodsInfo.value?.goods_id || '')
      el.setAttribute('data-title', goodsInfo.value?.goods_name || '')
      el.setAttribute('data-cat_id', goodsInfo.value?.cat_id || '')
      el.setAttribute('data-rec_mark', goodsInfo.value?.ext?.rec_mark || '')
      el.setAttribute('data-extra_mark', goodsInfo.value?.ext?.extra_mark || '')
      el.setAttribute('data-other_ext_mark', goodsInfo.value?.ext?.other_ext_mark || '')
      el.setAttribute('data-ext_marks', extMarks)
      el.setAttribute('data-other_d_ext_mark', goodsInfo.value?.dynamic_ext?.other_d_ext_mark || '')
      el.setAttribute('data-us-price', goodsInfo.value?.salePrice?.usdAmount || '')
      el.setAttribute('data-price', goodsInfo.value?.salePrice?.amount || '')
      el.setAttribute('data-us-origin-price', goodsInfo.value?.retailPrice?.usdAmount || '')
      el.setAttribute('data-discount', goodsInfo.value?.unit_discount || '')
      el.setAttribute('data-label', pretreatInfo.value?.label || '')
      el.setAttribute('data-page', goodsInfo.value?.thisPage || '') // 当前页码
      el.setAttribute('data-show-exclusive-price', goodsInfo.value?.exclusivePromotionPrice || '')
      el.setAttribute('data-spu-img', spuImg.value ? (_showSpuImg.value ? 'spupic_1' : 'spupic_0') : '')
      el.setAttribute('data-mall_tag_code', `${pretreatInfo.value?.mallTagsInfo?.mall_tags || ''}_${goodsInfo.value?.mall_code || '-'}`)
      el.setAttribute('data-store_code', goodsInfo.value?.store_code ?? '')
      el.setAttribute('data-promotion-id', mixPromotionInfo.map(item => item?.id).join('`') || '')
      el.setAttribute('data-type-id', mixPromotionInfo.map(item => item?.typeId).join('`') || '')
      el.setAttribute('data-quickship', goodsInfo.value?.quickship || '')
      el.setAttribute('data-best-deal', beltPriceCtrl.value?.showBestDeal ? 1 : '')
      el.setAttribute('data-follow-label', beltPriceCtrl.value?.followLabelVisible ? 1 : '')
      el.setAttribute('data-belt-label', pretreatInfo.value?.[labelsFromKey]?.beltLabel?.ana || '')
      el.setAttribute('data-locate-labels', locateLabelsCtrl.value?.locateLabelsAna || '')

      setLabelCarouselAna({ el, item: goodsInfo.value })

      // 31品类限定商家限时直降 wiki.pageId=1198803653
      let showSellerLimitedLabel = ''
      if (mixPromotionInfo.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)) {
        showSellerLimitedLabel = 'show_seller_limited_label'
      }
      el.setAttribute('data-show-seller-limited-label', showSellerLimitedLabel)

      const labelHot = ((!imgUpperLeft.value?.code && !locateLabels.value?.UPPER_LEFT_LABEL && showHotTag.value === 1)) ? 'show_label_hot' : ''
      el.setAttribute('data-show-label-hot', labelHot)

      if (saleout.value && saleout.value.show) {
        el.setAttribute('data-sold-out', 1)
      }
      if (config.showSellAttr) {
        el.setAttribute('data-sell-attr', pretreatInfo.value?.sellAttr ? `attribute_show_${pretreatInfo.value.sellAttr}` : 'attribute_null')
      }

      // 到手价的一些信息
      const estimatedPriceUsdAmount = estimatedPriceInfo.value.estimatedPrice?.usdAmount // 到手美元价
      const satisfiedVal = [2, 1][estimatedPriceInfo.value.satisfied] || ''
      const isABPrice = goodsInfo.value?.promotionInfo?.length && goodsInfo.value?.promotionInfo?.some(item => +item?.typeId === 32)
      isABPrice && el.setAttribute('data-is-ab-price', 1)
      if (satisfiedVal && estimatedPriceUsdAmount) {
        el.setAttribute('data-estimated-price', `${satisfiedVal}\`${estimatedPriceUsdAmount}`)
      }
      if(item.value.isHypernymGoods === true) {
        el.setAttribute('data-hypernym-goods', 1)
      }

    })
  }


  /**
   * 自定义埋点字段
   * @param {Array} attrArr [{ attr: xxx, value: xxx }]
   */
  const customizeElAttr = (attrArr = []) => {
    const elMain = imgContainerRef.value?.$el
    const elName = nameContainerRef.value?.$refs?.nameLink
    attrArr.forEach(({ attr, value }) => {
      elMain && elMain.setAttribute(attr, value)
      elName && elName.setAttribute(attr, value)
    })
  }

  return {
    setElAttr,
    setAttrForAnalysis,
    customizeElAttr,

    analysisWaitingQueue,
    clearAnalysisWaitingQueue,
  }
}
