<template>
  <p 
    class="product-card-price-v2-bottom-label"
    :class="[color]"
  >
    {{ text }}
  </p>
</template>

<script name="ProductCardPriceV2BottomLabel" setup>
import { toRefs } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  }
})


const { text } = toRefs(props)
</script>

<style lang="less" scoped>
/* stylelint-disable-next-line selector-class-pattern */
.product-card-price-v2-bottom-label {
  .line-camp(1);
  
  height: 14px;
  line-height: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  word-break: break-all;
  vertical-align: middle;
  
  font-size: 12px;

  // &.brown {
  //   color: @sui_color_club_rosegold_dark1;
  // }

  // &.origin {
  //   color: @sui_color_discount;
  // }
}
.brown {
  color: @sui_color_club_rosegold_dark1;
}
  
.origin {
  color: @sui_color_discount;
}
</style>
