<template>
  <div
    class="normal-price-ctn__prices"
    :class="priceCtnCls"
    :style="{'height': config.hideDiscountLabel ? priceCtnStyle.height : ''}"
  >
    <div class="normal-price-ctn__sale-price-wrapper">
      <!-- 促销icon集合 -->
      <template v-if="promotionIconList.length > 0 && !config.hidePromotionIcon">
        <i
          v-for="(promoIcon, i) in promotionIconList"
          :key="i"
          :class="[
            promoIcon,
            'sh_pc_sui_icon',
          ]"
        ></i>
      </template>

      <!-- 售价 -->
      <span 
        v-if="salePrice"
        :class="salePriceInfo.classList"
        :style="priceStyle.salePrice"
      >
        <CamelCasePrice
          :price="salePriceInfo.salePrice"
          :max-size="18"
          :min-size="12"
          :is-camel-case-price="config.isCamelCasePrice"
          :data-note="supportVueDebug ? '售价 取salePrice' : ''"
        />
      </span>

      <SuggestedSalePriceContainer
        v-if="suggestedPositionData.priceRight.show"
        :goods-info="goodsInfo"
        :special-data="specialData"
      />
    </div>

    <!-- 新划线价（合规价） -->
    <SuggestedSalePrice
      v-if="suggestedPositionData.priceBottom.show"
      :style="{ maxWidth: '100%', minWidth: 0 }"
      :value="suggestedPositionData.priceBottom.priceData"
      :label-text="suggestedPositionData.priceBottom.labelText"
      :flip-text="suggestedPositionData.priceBottom.flipText"
    />

    <!-- 老划线价（零售价） -->
    <span
      v-else-if="showRetailPrice"
      role="contentinfo"
      :aria-label="language.SHEIN_KEY_PC_17962 + retailPrice.amountWithSymbol"
      class="normal-price-ctn__retail-price"
      tabindex="0"
      :style="priceStyle.retailPrice"
      :data-note="supportVueDebug ? '划线价 取retailPrice' : ''"
    >
      {{ retailPrice.amountWithSymbol }}
    </span>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import CamelCasePrice from 'public/src/pages/components/CamelCasePrice.vue'
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import { getSuggestedPositionData, isFrPlSuggestedSalePrice, isDeSuggestedSalePrice } from '../../utils/priceDisplayTheResults'

export default {
  name: 'ProductCardNormalPrice',
  components: {
    SuggestedSalePrice,
    CamelCasePrice,
    SuggestedSalePriceContainer: defineAsyncComponent(
      () => import(/* webpackChunkName: "style-gallery" */'./SuggestedSalePriceContainer.vue'),
    ),
  },
  inject: ['item', 'labelsFromKey', 'config', 'language', 'constantData', 'supportVueDebug', 'specialData'],
  props: {
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
    pretreatInfo: {
      type: Object,
      default: () => ({}),
    },
    newFlashPromotion: {
      type: Object,
      default: () => ({}),
    },
    salePrice: {
      type: Object,
      default: () => ({}),
    },
    retailPrice: {
      type: Object,
      default: () => ({}),
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    // 付费会员的一些数据信息
    sheinClubInfo: {
      type: Object,
      default: () => ({})
    },
    soldOut: {
      type: Boolean,
      default: true,
    },
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({})
    },
    // 到手价相关信息
    estimatedInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isInversionVal: false, // 售价:合规价是否倒挂
    }
  },
  computed: {
    priceCtnStyle() {
      return this.config.style?.priceCtn || {}
    },
    lowestPriceLabelLang() {
      return this.goodsInfo.pretreatInfo?.suggestedSalePriceConfigInfo?.lowestPriceLabelLang || ''
    },
    suggestedSalePriceConfigInfoConfig() {
      return this.goodsInfo.pretreatInfo?.suggestedSalePriceConfigInfo?.config || {}
    },
    suggestedPositionData() {
      return getSuggestedPositionData(this.item.value, this.specialData)
    },
    priceCtnCls () {
      return {
        'normal-price-ctn__prices_over-hidden': this.config.hideDiscountLabel,
        'normal-price-ctn__prices_discount': this.showDiscountLabel,
        'flex-row': !this.goodsInfo.pretreatInfo?.suggestedSalePriceConfigInfo?.type
      }
    },
    /** the icon next to price **/
    promotionIconList() {
      let list = []

      const needSusaPrice = !!(this.goodsInfo.pretreatInfo?.suggestedSalePriceConfigInfo)
      if (needSusaPrice && this.isInversionVal) {
        return list
      }

      this.pretreatInfo.mixPromotionInfo &&
        this.pretreatInfo.mixPromotionInfo.forEach((i) => {
          if (!i.icon) return
          // app专属图标无需展示
          if (i.typeId !== 8) {
            if (i.typeId == 10) {
              if (this.beltLabel?.component == 'flash_purchase_belt') return
              // 闪电标（1: 普通闪购, 4: 分人群定价专区闪购, 5: 不分人群定价专区闪购）
              if (([1, 5].includes(+i.flash_type) || +i.flash_type === 4 && this.flashZonePriceWithSymbol)) {
                list.push(i.icon)
              }
              return
            }
            list.push(i.icon)
          }
        })
      return list.length > 0 ? list.slice(0, 1) : []
    },
    newFlashPrice() {
      return this.newFlashPromotion?.price || {}
    },
    showNewFlashPrice() {
      return (
        this.config.showNewFlashPriceOnSale &&
        this.newFlashPrice.amountWithSymbol
      )
    },
    sheinClubPrice() {
      return this.sheinClubInfo.price || {}
    },
    showSheinClubPrice() {
      return this.config.showSheinClubPriceOnSale && this.sheinClubPrice?.amountWithSymbol
    },
    isDiscount() {
      const salePriceAmount = Number(this.salePrice.amount)
      const retailPriceAmount = Number(this.retailPrice.amount)
      const newFlashPriceAmount = Number(this.newFlashPrice.amount)
      const flashZonePriceAmount = Number(this.flashZonePrice.amount)
      if ( this.showSheinClubPrice ) {
        return false
      } else {
        return this.showNewFlashPrice 
          ? retailPriceAmount > newFlashPriceAmount
          : flashZonePriceAmount > salePriceAmount || retailPriceAmount > salePriceAmount
      }
    },
    showRetailPrice() {
      return this.retailPrice.amountWithSymbol && this.config.showRetailPrice && !this.suggestedSalePriceConfigInfoConfig?.hideTargetBySuggestedSalePrice
        ? this.isDiscount
        : false
    },
    priceStyle() {
      const style = {
        salePrice: {},
        retailPrice: {}
      }
      const { salePriceFontSize, salePriceColor } = this.config.style || {}

      salePriceColor && (style.salePrice.color = salePriceColor)
      salePriceFontSize && (style.salePrice.fontSize = salePriceFontSize)

      return style
    },
    showDiscountLabel() {
      const suggestedSalePriceConfigInfo = this.item.value?.pretreatInfo?.suggestedSalePriceConfigInfo
      const suggestedSalePriceType = suggestedSalePriceConfigInfo?.type
      const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)

      return this.config.showDiscountLabelAfterPrice && !isDe
    },
    // 分人群专区闪购
    flashZonePrice() {
      return this.goodsInfo.flashZonePromotion?.price || {}
    },
    flashZonePriceWithSymbol() {
      return this.flashZonePrice?.amountWithSymbol || ''
    },
    estimatedPrice() {
      return this.estimatedInfo?.priceInfo ?? {}
    },
    isSheinClubEstimatedPrice() {
      return this.estimatedInfo?.isSheinClubEstimatedPrice ?? {}
    },
    // 限时直降活动，固定显示红色价格
    // showSellerLimitedLabel() {
    //   return this.pretreatInfo?.mixPromotionInfo?.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)
    // },
    // 合规价
    suggestedSalePriceInfo() {
      return this.goodsInfo?.suggestedSalePrice || ''
    },
    salePriceInfo() {
      let salePrice = null
      let showSuggestedSalePrice = false // 是否展示合规价
      const classList = ['normal-price-ctn__sale-price']
      const classTypeMap = {
        promo: 'normal-price-ctn__sale-price_promo', // 促销色
        sheinClub: 'normal-price-ctn__sale-price_sheinclub' // 付费会员色
      }

      if (this.estimatedPrice.amountWithSymbol) { // 到手价-占用现售价的坑位
        if (this.isSheinClubEstimatedPrice) {
          classList.push(classTypeMap['sheinClub'])
        } else {
          classList.push(classTypeMap['promo'])
        }
        salePrice = this.estimatedPrice
      } else if (this.showSheinClubPrice) {
        const isInversionOnSheinClub = this.sheinClubInfo.isInversion
        const inversionOnSheinClub = this.isInversionFn(isInversionOnSheinClub) // 付费会员价格倒挂
        showSuggestedSalePrice = this.isShowSuggestedSalePrice({ inversion: inversionOnSheinClub, classType: 'sheinClub', classTypeMap, classList })
        salePrice = this.sheinClubPrice
      } else if (this.showNewFlashPrice) {
        const isInversionOnNewFlash = this.newFlashPromotion.isInversion
        const inversionOnNewFlash = this.isInversionFn(isInversionOnNewFlash) // 新型闪购价格倒挂
        showSuggestedSalePrice = this.isShowSuggestedSalePrice({ inversion: inversionOnNewFlash, classType: 'promo', classTypeMap, classList })
        salePrice = this.newFlashPrice
      } else if (this.flashZonePriceWithSymbol) {
        const isInversionOnFlashZone = this.flashZonePromotion.isInversion
        const inversionOnFlashZone = this.isInversionFn(isInversionOnFlashZone) // 新型闪购价格倒挂
        showSuggestedSalePrice = this.isShowSuggestedSalePrice({ inversion: inversionOnFlashZone, classType: 'promo', classTypeMap, classList })
        salePrice = this.flashZonePrice
      } else if (this.salePrice.amountWithSymbol) {
        const salePriceAmount = Number(this.salePrice.amount)
        const retailPriceAmount = Number(this.retailPrice.amount)
        const suggestedSalePriceAmount = Number(this.suggestedSalePriceInfo?.amount)
        const isInversionOnPmsc = this.goodsInfo?.isInversion
        const inversionOnPmsc = this.isInversionFn(isInversionOnPmsc) // pmsc价格倒挂

        const isDiscount = (suggestedSalePriceAmount || retailPriceAmount) > salePriceAmount

        // 限时直降活动，固定显示红色价格
        const showSellerLimitedLabel = this.pretreatInfo?.mixPromotionInfo?.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)

        const useSpecialColor = isDiscount || this.isPromoPrice || showSellerLimitedLabel
        showSuggestedSalePrice = this.isShowSuggestedSalePrice({ inversion: inversionOnPmsc, useSpecialColor: !!useSpecialColor, classType: 'promo', classTypeMap, classList })
        salePrice = this.salePrice
      }

      return {
        salePrice,
        classList,
        showSuggestedSalePrice
      }
    }
  },
  methods: {
    // 是否倒挂
    isInversionFn(isInversion) {
      return Number(isInversion) === 1
    },
    isShowSuggestedSalePrice({ inversion, useSpecialColor, classType, classTypeMap, classList }) {
      this.isInversionVal = !!inversion

      const suggestedSalePriceType = this.goodsInfo.pretreatInfo?.suggestedSalePriceConfigInfo?.type
      const isFrPl = isFrPlSuggestedSalePrice(suggestedSalePriceType)
      const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)
      
      // 未倒挂且有extraFactor条件成立，使用特殊色
      const extraFactor = typeof useSpecialColor !== 'undefined' ? useSpecialColor : true
      
      if (isFrPl) {
        if (!inversion && extraFactor) classList.push(classTypeMap[classType])
        // 未倒挂且有合规价，展示合规价
        if (!inversion && this.suggestedSalePriceInfo?.amountWithSymbol) return true
        return false

      } else if (isDe && (this.specialData.show30DaysLowestPrice || this.specialData.priceInfoAndretailPriceEquivalent)) {
        classList.push(classTypeMap[classType])
        return true
      } else if (!suggestedSalePriceType && extraFactor) {
        classList.push(classTypeMap[classType])
      }
    }
  }
}
</script>

<style lang="less" scoped>

.normal-price-ctn {
  .flexbox();
  .space-between();
  .align-center();
  position: relative;
}
.normal-price-ctn {
  &__prices {
    max-width: 100%;
    
    .flexbox();
    flex-direction: column;
    align-items: flex-start;

    min-width: 0;
    .normal-price-ctn__sale-price-wrapper {
      max-width: 100%;
      align-items: baseline;

      &>.sh_pc_sui_icon {
        align-self: center;
      }
    }

    &.flex-row {
      flex-direction: row;
      .align-center();
    }

    flex-wrap: nowrap;
    
    margin-right: 2px;
    &_gap {
      padding-right: 10px;
    }
    /* stylelint-disable selector-class-pattern */
    .sh_pc_sui_icon_apponly_20px_promo,
    .sh_pc_sui_icon_time_16px_promo {
      margin-right: 4px;
    }
    &_discount {
      line-height: 21px;
      .normal-price-ctn__sale-price {
        font-size: 16px;
      }
      .normal-price-ctn__retail-price {
        margin-left: 2px;
      }
    }
    &_over-hidden {
      height: 18px;
      line-height: 18px;
      overflow: hidden;
    }
    .shein-club-discount_small {
      margin-right: 4px;
      margin-top: 0;
    }
  }
  &__sale-price-wrapper {
    .flexbox();
    .align-center();
  }
  &__sale-price,
  &__retail-price,
  &__discount-label {
    margin-right: 4px;
  }
  &__sale-price {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    &_promo {
      color: @sui_color_discount;
    }
    &_sheinclub {
      color: @sui_color_club_rosegold_dark1;
    }
  }

  &__retail-price {
    display: inline-block;
    font-size: 12px;
    color: @sui_color_gray_light1;
    text-decoration: line-through;
  }
}

</style>
