<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div 
    ref="swiperContainer"
    class="s-swiper-container" 
    :class="containerClass"
  >
    <div 
      ref="swiperContent"
      class="s-swiper-content"
      :style="swiperStyle"

      @mousemove="onmousemove"
      @mouseout="onmouseout"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script name="Swiper" setup>
import { ref, reactive, onMounted, onUnmounted, computed, nextTick } from 'vue'

const emits = defineEmits(['loopPlayStart'])

// eslint-disable-next-line vue/no-setup-props-destructure
const { direction, loop, autoPlay, speed, delay, delayLoopInitTime, className, observer, intersectionObserver } = defineProps({
  // 滚动方向，横项： horizontal 竖向： vertical
  direction: {
    type: String,
    default: 'horizontal'
  },
  // 是否无限滚动
  loop: {
    type: Boolean,
    default: true
  },
  //是否自动轮播
  autoPlay: {
    type: Boolean,
    default: true
  },
  //滑动所需要的时间
  speed: {
    type: [String, Number],
    default: 300
  },
  //两次滑动间隔的时间
  delay: {
    type: Number,
    default: 2000
  },
  delayLoopInitTime: { // 第一次轮播动画多久开始播放
    type: Number,
    default: 0,
  },
  // 是否监听swiper item长度变化
  observer: {
    type: Boolean,
    default: false
  },
  // 自定义class
  className: {
    type: String,
    default: ''
  },
  intersectionObserver: {   // 是否需要只在可视时才自动轮播
    type: Boolean,
    default: false
  }
})

const isInit = ref(false)

const swiperContainer = ref(null)
const swiperContent = ref(null)

// 当前显示的swiper Item Index
const activeIndex = ref(0)

const itemLength = ref(0)

// swiper item的宽高
const swiperItemWidth = ref(0)
const swiperItemHeight = ref(0)

// 轮播的圈数
const loopIndex = ref(0)

const translate = reactive({
  x: 0,
  y: 0
})

const swiperStyle = computed(() => {
  return {
    transform: `translate3d(${translate.x}px, ${translate.y}px, 0)`,
    'transition-duration': `${isInit.value ? speed : 0}ms`
  }
})


onMounted(() => {
  initSwiper()

  nextTick(() => {
    startAutoBefore()
    if (observer) {
      initObserver()
    }
  })
})

onUnmounted(() => {
  timerId && clearTimeout(timerId)

  domObserverFun && domObserverFun.disconnect()
})

// class样式设置
const containerClass = computed(() => {
  const containerClassName = []

  if (className) {
    containerClassName.push(className)
  }

  if (direction === 'horizontal') {
    containerClassName.push('direction-horizontal')
  } else if (direction === 'vertical') {
    containerClassName.push('direction-vertical')
  }

  return containerClassName
})

// 初始化swiper item监听
let domObserverFun = null
const initObserver = () => {
  const swiperContentDom = swiperContent.value

  domObserverFun = new MutationObserver(() => {
    initSwiper()
  })

  domObserverFun.observe(swiperContentDom, {
    childList: true
  })
}

/**
 * 初始化swiper
 */
const initSwiper = () => {
  const swiperContentDom = swiperContent.value
  const itemDomList = swiperContentDom.querySelectorAll('.s-swiper-item:not([data-logic])')

  itemLength.value = itemDomList?.length ?? 0
  loopIndex.value = 0

  if (itemLength.value > 1 && autoPlay && loop) {
    copyDom()

    startAutoBefore()

    slideTo(1)

    nextTick(() => {
      isInit.value = true
    })
  } else {
    isInit.value = true
  }
}

// 复制头和尾
const copyDom = () => {
  const swiperContentDom = swiperContent.value
  const itemDomList = gteItemList().list

  // 先删除旧的再插入
  const oldDomList = swiperContentDom.querySelectorAll('.s-swiper-item[data-logic]')
  oldDomList.forEach((dom) => {
    dom.remove()
  })

  const first = itemDomList[0].cloneNode(true)
  const last = itemDomList[itemDomList.length - 1].cloneNode(true)

  first.setAttribute('data-logic', 1)
  last.setAttribute('data-logic', 1)

  swiperContentDom.insertBefore(last, itemDomList[0])
  swiperContentDom.appendChild(first)
}

const gteItemList = () => {
  const swiperContentDom = swiperContent.value
  const itemDomList = swiperContentDom.querySelectorAll('.s-swiper-item:not([data-logic])')
  return {
    list: itemDomList,
    length: itemDomList?.length ?? 0
  }
}


// 滑动处理 start
let timerId = null
let IntersectionObserverInstance = null

// 初始化滚动可视化监听实例
const initIntersectionObserverInstance = () => {
  if (!IntersectionObserverInstance) {
    IntersectionObserverInstance = new IntersectionObserver((entries) => {
      const intersectionRatio = entries[0].intersectionRatio

      if (intersectionRatio <= 0) {
        stopAuto()
      } else if (intersectionRatio > 0) {
        startAuto()
      }
    }, {
      thresholds: [0, 1]
    })
  }

  IntersectionObserverInstance.observe(swiperContainer.value)
}

const startAutoBefore = () => {
  const length = gteItemList().length

  if (autoPlay && length > 1) {
    if (intersectionObserver && !IntersectionObserverInstance) {
      initIntersectionObserverInstance()
    } else {
      startAuto({
        isFirst: true
      })
    }
  }
}

// 开始自动轮播
const startAuto = (options = {}) => {
  if (timerId) {
    stopAuto()
  }
  const isFirst = options?.isFirst

  const delayTime = isFirst && delayLoopInitTime > 0 ? delayLoopInitTime : delay
  
  timerId = setTimeout(() => {
    const swiperContentDom = swiperContent.value
    // 重置
    if (activeIndex.value >= itemLength.value) {
      activeIndex.value = 0
      swiperContentDom.style['transition-duration'] = '0ms'
      swiperContentDom.style.transform = 'translate3d(0, 0, 0)'

      loopIndex.value++
    } else if (activeIndex.value < 0) {
      activeIndex.value = itemLength.value - 1
      swiperContentDom.style['transition-duration'] = '0ms'
      swiperContentDom.style.transform = `translate3d(0, ${ -swiperItemHeight.value * activeIndex.value }px, 0)`
    }

    slideTo(activeIndex.value + 1)

    startAuto()
  }, delayTime)
}

// 暂停自动轮播
const stopAuto = () => {
  if (timerId) {
    clearTimeout(timerId)
    timerId = null
  }
}

/**
 * 滑动到指定index
 * @param {number} index 
 */
const slideTo = async (index) => {
  const swiperContentDom = swiperContent.value

  const itemDomList = swiperContentDom?.querySelector('.s-swiper-item:not([data-logic])')

  swiperItemWidth.value = itemDomList?.offsetWidth ?? 0
  swiperItemHeight.value = itemDomList?.offsetHeight ?? 0

  const slideIndex = index

  if (direction === 'horizontal') {
    translate.x = -swiperItemWidth.value * slideIndex
    translate.y = 0
  } else if (direction === 'vertical') {
    translate.x = 0
    translate.y = -swiperItemHeight.value * slideIndex
  }

  activeIndex.value = index

  emits('loopPlayStart', {
    index: activeIndex.value - 1,
    loopIndex: loopIndex.value
  })
}
// 滑动处理 end

// 鼠标移入
const onmousemove = () => {
  // stopAuto()
}

// 鼠标移出
const onmouseout = () => {
  // if (autoPlay) {
  //   startAuto({
  //     isFirst: true
  //   })
  // }
}

</script>

<style lang="less" scoped>
.s-swiper-container {
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  &.transparent {
    opacity: 0;
  }

  // 横向
  &.direction-horizontal {
    .s-swiper-content {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  // 竖向
  &.direction-vertical {
    .s-swiper-content {
      flex-direction: column;
      
    }
  }

}

.s-swiper-content {
  display: flex;
  width: 100%;
  height: 100%;
  transition-timing-function: linear;
}
</style>
