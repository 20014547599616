<template>
  <div 
    v-if="discountInfo.unitDiscountString"
    class="product-card__discount-label notranslate"
    :class="{
      'discount-label_default': type == 'default',
      'discount-label_promo': type == 'promo',
      'discount-label_flash': type == 'flash',
      'discount-label_discount': type == 'discount' || type == 'newDiscount',
      'discount-label_discount-new': type == 'newDiscount',
    }"
  >
    {{ discountInfo.unitDiscountString }}
  </div>
</template>

<script>
export default {
  name: 'DiscountLabel',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    discountInfo: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="less">
.product-card {
  &__discount-label {
    font-size: 12px;
    line-height: 22px;
    direction: ltr /*rtl:ignore*/;
    padding: 0 6px;
    &.discount-label {
      &_default {
        background: @sui_color_main;
        color: #fff;
      }
      &_promo {
        color: #fff;
        background: @sui_color_promo;
      }
      &_flash {
        background: @sui_color_flash;
        /*sh:begin*/
        color: #222;
        /*rw:begin*/
        color: #fff;
      }
      &_discount {
        height: 16px;
        line-height: 16px;
        padding: 0 2px 0 3px;
        border: 1px solid @sui_color_discount;
        color: @sui_color_discount;
        border-radius: 2px;
      }
      &_discount-new {
        color: #fff;
        background-color: @sui_color_discount;
      }
    }
  }
}
</style>
