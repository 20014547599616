import { computed } from 'vue'

import { isDeSuggestedSalePrice } from '../utils/priceDisplayTheResults'

export const useDiscount = ({ specialData, goodsInfo, estimatedInfo, sheinClub }) => {
  const { pretreatInfo, discountPrice } = goodsInfo.value || {}
  const { discountInfo, suggestedSalePriceConfigInfo } = pretreatInfo || {}
  const { suggestedSalePriceType, unitDiscountString: normalUnitDiscountString, retailDiscountPrice, retailDiscountPercentString } = discountInfo || {}

  const { priceInfo, estimatedDiscount, estimatedDiscountPrice } = estimatedInfo.value || {}
  const estimatedPriceWithSymbolVal = priceInfo.amountWithSymbol

  const { discountPercentString, sheinClubPriceWithSymbolVal, discount_amount, retail_discount_price } = sheinClub || {}

  // 是否为德国合规价
  const isSuggestedSpecialDe = isDeSuggestedSalePrice(suggestedSalePriceConfigInfo?.type)

  const getDiscountInfo = () => {
    const discountInfo = {
      suggestedSalePriceType,
      show: false,
    }

    const setDiscountPrice = (discountPrice) => ({ discountPrice: discountPrice || '' })
    const setUnitDiscountString = (unitDiscountString) => ({ unitDiscountString: unitDiscountString || '', show: true })

    const getValue = ({ discountPrice, unitDiscountString }) => {
      return {
        ...setDiscountPrice(discountPrice),
        ...setUnitDiscountString(unitDiscountString)
      }
    }

    let value = null
    if (estimatedPriceWithSymbolVal && estimatedDiscount) { // 到手价
      value = getValue({ discountPrice: estimatedDiscountPrice?.amountWithSymbol, unitDiscountString: estimatedDiscount })

    } else if (sheinClubPriceWithSymbolVal && isSuggestedSpecialDe && specialData?.showSusaPrice && sheinClub.retailDiscountPercentString) { // 付费会员 德国合规场景
      value = getValue({ discountPrice: retail_discount_price?.amountWithSymbol, unitDiscountString: sheinClub.retailDiscountPercentString })

    } else if (sheinClubPriceWithSymbolVal) { // 付费会员 普通场景
      value = getValue({ discountPrice: discount_amount?.amountWithSymbol, unitDiscountString: discountPercentString })

    } else if (isSuggestedSpecialDe && specialData?.showSusaPrice && pretreatInfo?.discountInfo?.retailDiscountPercent) { // 普通折扣和新型闪购 德国合规场景
      value = getValue({ discountPrice: retailDiscountPrice?.amountWithSymbol, unitDiscountString: retailDiscountPercentString })

    } else if (normalUnitDiscountString) { // 普通折扣和新型闪购 普通场景
      value = getValue({ discountPrice: discountPrice?.amountWithSymbol, unitDiscountString: normalUnitDiscountString })
    }

    if (!value) return

    return Object.assign(discountInfo, value)
  }

  // 折扣信息
  const finalDiscountInfo = computed(() => {
    return getDiscountInfo({ discountInfo })  // 折扣比例和可省金额设值
  })

  return finalDiscountInfo
}
