<template>
  <div 
    class="rmvip-price" 
    :tabindex="adaLevel"
  >
    <div class="rmvip-price__container">
      <span>{{ price }}</span>
      <span class="rmvip-price__icon"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RomweVipPrice',
  props: {
    price: {
      type: [String, Number],
      default: 0,
    },
    adaLevel: {
      type: [String, Number],
      default: 0,
    },
  },
}
</script>

<style lang="less" scoped>
@rw_exclusive_price: '/she_dist/images/sheinvip2/rw_vip_price_icon-9140c93afa.png';
.rmvip-price {
  &__container {
    display: inline-block;
    height: 14px;
    font-size: 0px;
    font-weight: bold;
    color: #936d0c;
    line-height: 16px;
    background-color: #ffe995;
    border-radius: 7px;
    padding-left: 4px;
    > span {
      vertical-align: top;
      font-size: 12px;
    }
  }
  &__icon {
    display: inline-block;
    height: 100%;
    width: 31px;
    background-color: #333;
    margin-left: 4px;
    border-radius: 0 7px 7px 0;
    background-size: cover;
    background-image: url(@rw_exclusive_price);
  }
}
</style>
